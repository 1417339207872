<template>
<div>
  <v-row>
   
    <v-dialog v-if="materialPicked" v-model="imageDialogVisible" width="500">
      <v-card>
        <v-card-title class="headline">
          Images for {{ materialPicked.title }}
        </v-card-title>
        <v-card-text>
          <div class="d-flex justify-space-around dense center-aligned">
            <v-btn icon :disabled="picIndex === 0" @click="prevImage">
              <v-icon>{{ icons.mdiChevronLeft }}</v-icon>
            </v-btn>
            <img class="preview" :src="whichImage()" />
            <v-btn
              icon
              :disabled="
                materialPicked.pit ||
                (materialPicked.images &&
                  picIndex === materialPicked.images.length - 1)
              "
              @click="nextImage"
            >
              <v-icon>{{ icons.mdiChevronRight }}</v-icon>
            </v-btn>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="success" @click="closeDialog"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="filterBox" width="500">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6" style="text-align: center">
              <v-btn color="error" rounded dark @click="searchByAction(1)">
                GETTING RID OF MATERIAL
              </v-btn>
            </v-col>
            <v-col cols="12" md="6" style="text-align: center">
              <v-btn color="success" rounded dark @click="searchByAction(2)">
                TAKING MATERIAL
              </v-btn>
            </v-col>
          </v-row>
          <!--<v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-select
                v-model="searchAction"
                outlined
                dense
                :items="actionTypes"
                item-text="description"
                item-value="action"
                placeholder="By Action"
                label="By Action"
                hide-details="auto"
                return-object
              ></v-select>
            </v-col>
          </v-row>-->

          <v-row>
            <v-col cols="12" md="12">
              <v-autocomplete
                outlined
                dense
                clearable
                v-model="searchType"
                :items="itemTypes"
                item-text="description"
                item-value="itemType"
                hide-details
                label="Material Type"
                @change="selectedMaterialType"
              >
              </v-autocomplete>
              <!--<v-select
                v-model="searchType"
                outlined
                dense
                :items="itemTypes"
                item-text="description"
                item-value="itemType"
                placeholder="By Material Type"
                label="By Material Type"
                hide-details="auto"
                return-object
              ></v-select>-->
            </v-col>
            <!--<v-col
              cols="12"
              md="6"
            >
              <v-select
                v-model="searchSubtype"
                :disabled="subitemTypes.length <= 0"
                outlined
                dense
                :items="subitemTypes"
                item-text="itemSubtype"
                item-value="itemSubtype"
                placeholder="By Material Sub-Type"
                label="By Material Subtype"
                hide-details="auto"
                return-object
              ></v-select>
            </v-col>-->
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <h4>Search by Proximity</h4>
              {{ showProximityFilter(searchProximity) }}
              <v-slider
                v-model="searchProximity"
                max="250"
                step="25"
                @change="proximityBound"
              ></v-slider>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="8">
              <h4>Search by Quantity</h4>
              {{ showWeightFilter(searchWeight) }}
              <v-range-slider
                v-model="searchWeight"
                max="500"
                step="1"
              ></v-range-slider>
            </v-col>
            <v-col cols="12" md="4" style="position: relative; top: 25px">
              <v-select
                v-model="searchMetricType"
                outlined
                dense
                label="Tons/Yards"
                hide-details="auto"
                :items="metricTypes"
                item-text="description"
                item-value="itemType"
                return-object
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <h4>Search by Price Range</h4>
              {{ showPriceFilter(searchPrice) }}
              <v-range-slider
                v-model="searchPrice"
                max="2500"
                step="10"
              ></v-range-slider>
            </v-col>
          </v-row>
          <!--
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-select
                v-model="searchTruckingType"
                outlined
                dense
                label="Trucking Options"
                hide-details="auto"
                :items="truckOptions"
                item-text="description"
                item-value="itemType"
                return-object
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-select
                v-model="searchMachineType"
                outlined
                dense
                label="Machine Options"
                hide-details="auto"
                :items="machineOptions"
                item-text="description"
                item-value="itemType"
                return-object
              ></v-select>
            </v-col>
          </v-row>
          -->
        </v-card-text>
        <v-card-actions>
          <v-btn color="secondary" @click="resetFilter"> Reset </v-btn>

          <v-spacer></v-spacer>

          <v-btn color="success" @click="toggleFilterDialog"> Filter </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-col cols="12">
    <div @click="openLink">
      <img on class="icon-logo" src="@/assets/images/logos/main-logo.png" />
    </div>
      <v-row v-if="loggedIn" class="py-3">
        <v-col cols="12">
          <v-row style="float: right">
            <v-col class="text-center pb-0 align-self-end" cols="12" sm="3">
              <v-btn small color="alternate" rounded dark @click="goLogout()">
                <span style="color: black">Logout</span>
                <v-icon dark right class="black-icon">
                  {{ icons.mdiLogout }}
                </v-icon>
              </v-btn>
            </v-col>
            <v-col class="text-center pb-0 align-self-end" cols="6" sm="4">
              <v-btn small color="alternate" rounded dark @click="postAd(1)">
                <span style="color: black">Create a Post</span>
                <v-icon dark right class="black-icon">
                  {{ icons.mdiPlus }}
                </v-icon>
              </v-btn>
            </v-col>
            <v-col class="text-center pb-0 align-self-end" cols="6" sm="5">
              <v-btn
                small
                color="alternate"
                rounded
                dark
                @click="goMyAccount()"
              >
                <span style="color: black">My Account</span>
                <v-icon dark right class="black-icon">
                  {{ icons.mdiAccountCircle }}
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row v-if="!loggedIn" class="py-3">
        <v-col cols="12">
          <v-row style="float: right">
            <v-col class="text-center pb-0 align-self-end" cols="12" sm="3">
              <v-btn small color="alternate" rounded dark @click="goLogin()">
                <span style="color: black">Login</span>
                <v-icon dark right class="black-icon">
                  {{ icons.mdiLogin }}
                </v-icon>
              </v-btn>
            </v-col>
            <v-col class="text-center pb-0 align-self-end" cols="6" sm="4">
              <v-btn small color="alternate" rounded dark @click="postAd(1)">
                <span style="color: black">Create a Post</span>
                <v-icon dark right class="black-icon">
                  {{ icons.mdiPlus }}
                </v-icon>
              </v-btn>
            </v-col>
            <v-col class="text-center pb-0 align-self-end" cols="6" sm="5">
              <v-btn small color="alternate" rounded dark @click="goRegister()">
                <span style="color: black">Create Account</span>
                <v-icon dark right class="black-icon">
                  {{ icons.mdiAccountCircle }}
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- <v-row>
        <v-col id="main-logo" class="hero-logo" cols="12">
          <img src="@/assets/images/logos/main-logo.png" />
        </v-col>

        <v-col
          cols="6"
          style="padding: 0px;"
        >
          <div
            class="hero-image sell-img"
            style="text-align: center;"
          ></div>
        </v-col>
        <v-col
          cols="6"
          style="padding: 0px;"
        >
          <div
            class="hero-image take-img"
            style="text-align: center;"
          ></div>
        </v-col>
      </v-row> -->

      <!-- Strip Section
      <v-row class="hero-sub-image">
        <v-col
          cols="6"
          style="padding: 0px;"
        >
          <div class="hero-text">
            <a
              class="home-link"
              @click="highlightSale()"
            >
              <h1>I AM GETTING RID OF MATERIAL</h1>
            </a>
          </div>
        </v-col>
        <v-col
          cols="6"
          style="padding: 0px;"
        >
          <div class="hero-text">
            <a
              class="home-link"
              @click="highlightTake()"
            >
              <h1>I AM TAKING MATERIAL</h1>
            </a>
          </div>
        </v-col>
      </v-row>
      -->
      <v-row v-if="loading">
        <v-col cols="12">
          <div class="text-center">
            <h2 class="primary--text mb-4">Loading, please wait ...</h2>
            <v-progress-circular
              indeterminate
              size="75"
              color="primary"
              rotate="0"
            ></v-progress-circular>
          </div>
        </v-col>
      </v-row>

      <v-row
        v-if="!loading"
        style="background-color: #e6e6e6; justify-content: space-between"
      >
        <v-col cols="12" sm="6" style="text-align: center">
          <v-btn
            x-large
            color="error"
            dark
            class="btn-hero"
            @click="searchByAction(1)"
            style="border-color: red !important; border-radius: 10px"
          >
            Source Dirt
            <v-icon dark right>
              {{ icons.mdiMapMarker }}
            </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" sm="6" style="text-align: center">
          <v-btn
            x-large
            color="success"
            dark
            class="btn-hero"
            @click="searchByAction(2)"
            style="border-color: green !important; border-radius: 10px"
          >
            <v-icon dark right> {{ icons.mdiMapMarker }} </v-icon>&nbsp;&nbsp;
            Give Away Dirt
          </v-btn>
        </v-col>
      </v-row>

      <v-row v-if="!loading">
        <v-col
          cols="12"
          class="text-center"
          style="background-color: #e6e6e6; color: black; padding: 0px"
        >
          <!-- <h1>YOUR FREE ONLINE MATERIAL MARKETPLACE!</h1> -->
        </v-col>
      </v-row>
      <v-row
        v-if="!loading"
        style="padding: 10px 5%; background-color: #e6e6e6"
      >
        <v-col cols="12">
          <v-row
            style="
              padding: 0px 3%;
              background-color: white;
              border: 1px solid #e6e6e6;
              border-radius: 20px;
            "
          >
            <v-col cols="12" sm="6">
              <v-autocomplete
                outlined
                dense
                clearable
                v-model="keyword"
                :items="itemTypes"
                item-text="description"
                hide-details
                label="What material are you searching for?"
                @change="selectedMaterial"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" style="height: 65px">
              Search by Proximity: {{ showProximityFilter(searchProximity) }}
              <v-slider
                v-model="searchProximity"
                max="250"
                step="25"
                @change="proximityBound()"
              ></v-slider>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row v-if="!loading" class="displaycontainer">
        <v-col
          id="material-boxes"
          md="6"
          sm="6"
          cols="12"
          class="scroll-column"
          style="padding: 10px 15px"
        >
          <v-container >
            <v-row>
              <v-col
                v-for="(mat, index) in materialData"
                :key="index"
                cols="12"
                sm="12"
                md="6"
              >
                <v-card
                  :key="index"
                  style="height: 280px; width:350px;"
                  :id="mat.id"
                  @click="relocateMap(mat)"
                >
                  <!-- <v-icon style="color:red">
              {{ icons.mdiMapMarker }}
            </v-icon> -->
                  <span
                    :style="{
                      color:
                        mat.post_type === 'selling'
                          ? 'red'
                          : !mat.pit && mat.post_type !== 'selling'
                          ? 'green'
                          : 'grey',
                      fontSize: '22px',
                      fontWeight: '500',
                      position: 'absolute',
                      top: 0,
                      marginLeft: '5px',
                    }"
                    >●</span
                  >
                  <!-- <div class="image-gallery" v-if="(mat.images !== undefined && mat.images !== null && mat.images.length > 0)" @click="loadImages(mat)">
                <img class="picimg" :src="mat.images[0]" />
              </div> -->
                  <v-card-title
                    v-if="mat.pit"
                    class="custom-title"
                    style="display: block"
                  >
                    <a
                      @click="openPitURL(mat)"
                      style=" color: black;font-size:18px; font-weight-bold"
                      >{{ mat.name }}</a
                    >
                  </v-card-title>
                
                  <v-card-title
                    v-else
                    style="display: flex; padding-bottom: 10px"
                    class="custom-title"
                  >
                    <a
                      style=" white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;color: black; font-size:18px; font-weight-bold"
                      >{{ mat.title }}</a
                    ><br />
                  </v-card-title>
                  <v-card-text v-if="mat.pit">
                    <div class="text-base">
                      <div style="display: flex">
                        <span
                          class=""
                          style="
                            color: black;
                            font-size: 14px;
                            margin-right: 5px;
                          "
                          >Address:
                        </span>
                        <span
                          class="text-caption"
                          style="
                            font-size: 14px;
                            color: grey;
                            margin-top: 2px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                          "
                          >{{ mat.formatted_address }}</span
                        >
                      </div>

                      <br />
                    </div>
                    {{ mat.description }}
                  </v-card-text>
                  <v-card-text v-else>
                    <div
                      v-if="mat.post_type === 'selling'"
                      class="text--primary text-base"
                    >
                      <div style="margin-bottom:3px;">
                        <span
                          class=""
                          style="
                            display: inline-block;
                            color: black;
                            font-size: 14px;
                            margin-right: 5px;
                          "
                          >Size:
                        </span>
                        <span
                          class="text-caption"
                          style="
                            display: inline-block;
                            font-size: 14px;
                            color: grey;
                          "
                          >{{ mat.amount }} {{ mat.metric }}</span
                        >
                      </div>
                      <div style="margin-bottom:3px;">
                        <span
                          class=""
                          style="
                            display: inline-block;
                            color: black;
                            font-size: 14px;
                            margin-right: 5px;
                          "
                          >Type:
                        </span>
                        <span
                          class="text-caption"
                          style="
                            display: inline-block;
                            color: grey;
                            font-size: 14px;
                          "
                          >{{ mat.type }} // {{ mat.subtype }}</span
                        >
                      </div>
                      <div style="margin-bottom:3px;">
                        <span
                          class=""
                          style="
                            display: inline-block;
                            color: black;
                            font-size: 14px;
                            margin-right: 5px;
                          "
                          >Price:
                        </span>
                        <span
                          class="text-caption"
                          style="
                            display: inline-block;
                            color: grey;
                            font-size: 14px;
                          "
                          >${{ mat.price }}</span
                        >
                      </div>
                    </div>
                    <div v-else class="text--primary text-base">
                      <div style="margin-bottom: 3px">
                        <span
                          class=""
                          style="
                            display: inline-block;
                            color: black;
                            font-size: 14px;
                            margin-right: 5px;
                          "
                          >Size:
                        </span>
                        <span
                          class="text-caption"
                          style="
                            display: inline-block;
                            font-size: 14px;
                            color: grey;
                          "
                          >{{ mat.amount }} {{ mat.metric }}</span
                        >
                      </div>
                      <div style="margin-bottom: 3px">
                        <span
                          class=""
                          style="
                            display: inline-block;
                            color: black;
                            font-size: 14px;
                            margin-right: 5px;
                          "
                          >Type:
                        </span>
                        <span
                          class="text-caption"
                          style="
                            display: inline-block;
                            color: grey;
                            font-size: 14px;
                          "
                          >{{ mat.type }} // {{ mat.subtype }}</span
                        >
                      </div>
                    </div>
                    <span
                      v-if="mat.public_address"
                      style="color: black; display: block; margin: 0px 0 10px 0"
                      >{{ mat.formatted_address }}</span
                    >
                    <!-- <span
                      v-if="
                        mat.preferred_contact === 'phone' ||
                        mat.preferred_contact === 'both'
                      "
                      style="color: black; display: block"
                      >Phone: {{ mat.phone }}</span
                    > -->
                    <!-- <span
                      v-if="
                        mat.preferred_contact === 'email' ||
                        mat.preferred_contact === 'both'
                      "
                      style="color: black; display: block"
                      >Email:
                      <a :href="`mailto:${mat.email}`">{{ mat.email }}</a></span
                    > -->
                    {{ mat.description }}
                  </v-card-text>
                  <div
                  :key="index"
                  @click="callPhoneNumber(mat.phone)"
                  @mouseenter="changeText(mat.phone)" @mouseleave="resetText(`Contact`)"
                    style="
                      background-color: #f0f0f0;
                      padding: 8px;
                      border-radius: 4px;
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      position: absolute;
                      bottom: 0;
                      width: 100%;
                    "
                  >
                    <div >
                      <span
                      
                        class=""
                        style="
                          display: inline-block;
                          color: black;
                          font-size: 14px;
                          margin-right: 5px;
                        "
                        >{{mat.phone===originalText?originalText:"Contact" }}
                      </span>
                      <!-- <span
                        class="text-caption"
                        style="
                          display: inline-block;
                          color: black;
                          font-size: 14px;
                        "
                        >{{ originalText||"" }}</span
                      > -->
                    </div>
                  </div>
                  <div>
            <div  class="container1">
                       <span
                          class="text-caption flex-starts"
                           style="align-self-start;bottom:0;position:absolue;"
                          >{{getTheCity(mat.address) }}</span>
                  </div> 
                  <div class="container">
                  
                    <span
                      style="align-self-end;"
                      class="text-caption flex-end"
                      >{{ humanReadable(mat.published_on) }}</span
                    >
                  </div>
                  </div>

                  <!--<v-card-actions
              class="d-flex justify-space-around dense"
            >
              <v-btn
                icon
                @click="relocateMap(mat)"
              >
                <img
                  v-if="mat.pit"
                  src="@/assets/images/misc/location-pit.png"
                  height="25"
                />
                <img
                  v-if="!mat.pit && mat.post_type === 'selling'"
                  src="@/assets/images/misc/location-sell.png"
                  height="25"
                />
                <img
                  v-if="!mat.pit && mat.post_type === 'looking'"
                  src="@/assets/images/misc/location-take.png"
                  height="25"
                />
              </v-btn>
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-if="mat.pit"
                    icon
                    color="success"
                    @click="openPitURL(mat)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>{{ icons.mdiWeb }}</v-icon>
                  </v-btn>
                </template>
                <span>Click here for website</span>
              </v-tooltip>


              <v-btn
                icon
                color="error"
                :disabled="!mat.pit && (mat.images === undefined || mat.images === null || mat.images.length <= 0)"
                @click="loadImages(mat)"
              >
                <v-icon>{{ icons.mdiImageMultiple }}</v-icon>
              </v-btn>
            </v-card-actions>-->
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-col md="6" sm="6" cols="12" style="padding: 0px">
          <div ref="canadaWide" class="canada-wide"></div>
          <GMapMap
            ref="mainMap"
            :center="center"
            :zoom="zoom"
            style="height: 45rem"
            @zoom_changed="zoomResized"
          >
            <GMapMarker
              v-for="(m, index) in materialMarkers"
              :key="index"
              :icon="getCustom(m)"
              :position="m.markerLocation"
              @click="markerPicked(m)"
              @mouseover="markerHover(m)"
              @mouseout="markerClear(m)"
            >
              <GMapInfoWindow
                :opened="markerSelected && markerSelected.id === m.id"
              >
                <div v-if="m.pit" class="font-weight-bold">
                  <div class="pit-preview">
                    <img v-if="m.logo_image" height="50" :src="m.logo_image" />
                  </div>
                  {{ m.name }}<br />
                  {{ m.phone }}
                </div>
                <div v-else class="font-weight-bold">
                  <v-card>
                    <v-card-title style="display: block; padding-bottom: 0px">
                      {{ m.title }}<br />
                      <span class="text-caption">{{
                        humanReadable(m.published_on)
                      }}</span>
                    </v-card-title>
                    <v-card-text>
                      <p
                        v-if="m.post_type === 'selling'"
                        class="text--primary text-base"
                      >
                        <span class="font-weight-bold"
                          >{{ m.amount }} {{ m.metric }}</span
                        >
                        <br />
                        <span class="text-caption"
                          >{{ m.type }} // {{ m.subtype }}</span
                        >
                        <br />
                        <span class="font-weight-bold info--text"
                          >${{ m.price }}</span
                        >
                      </p>
                      <p v-else class="text--primary text-base">
                        <span class="text-caption"
                          >{{ m.type }} // {{ m.subtype }}</span
                        >
                        <br />
                        <span v-if="m.public_address">{{
                          m.formatted_address
                        }}</span>
                      </p>
                      <p>
                        <span
                          v-if="
                            m.preferred_contact === 'phone' ||
                            m.preferred_contact === 'both'
                          "
                          class="font-weight-bold info--text"
                          >{{ m.phone }}</span
                        >
                        <span
                          v-if="
                            m.preferred_contact === 'email' ||
                            m.preferred_contact === 'both'
                          "
                          class="font-weight-bold info--text"
                          >{{ m.email }}</span
                        >
                      </p>
                      {{ m.description }}
                    </v-card-text>
                    <!--<v-card-actions
                      class="d-flex justify-space-around dense"
                    >
                      <v-btn
                        icon
                        color="error"
                        :disabled="!m.pit && (m.images === undefined || m.images === null || m.images.length <= 0)"
                        @click="loadImages(m)"
                      >
                        <v-icon>{{ icons.mdiImageMultiple }}</v-icon>
                      </v-btn>
                    </v-card-actions>-->
                  </v-card>
                </div>
              </GMapInfoWindow>
            </GMapMarker>
          </GMapMap>
        </v-col>
      </v-row>

      <!-- my code........................... -->
       
<v-row v-if="!loading" class="mobileDisplay">
  <v-col md="6" sm="6" cols="12" style="padding: 0px">
          <div ref="canadaWide" class="canada-wide"></div>
          <GMapMap
            ref="mainMap"
            class="mobileDisplay"
            :center="center"
            :zoom="mobzoom"
            style="height: 45rem"
            @zoom_changed="mobzoomResized"
          >
            <GMapMarker
              v-for="(m, index) in materialMarkers"
              :key="index"
              :icon="getCustom(m)"
              :position="m.markerLocation"
              @click="markerPicked(m)"
              @mouseover="markerHover(m)"
              @mouseout="markerClear(m)"
            >
              <GMapInfoWindow
                :opened="markerSelected && markerSelected.id === m.id"
              >
                <div v-if="m.pit" class="font-weight-bold">
                  <div class="pit-preview">
                    <img v-if="m.logo_image" height="50" :src="m.logo_image" />
                  </div>
                  {{ m.name }}<br />
                  {{ m.phone }}
                </div>
                <div v-else class="font-weight-bold">
                  <v-card>
                    <v-card-title style="display: block; padding-bottom: 0px">
                      {{ m.title }}<br />
                      <span class="text-caption">{{
                        humanReadable(m.published_on)
                      }}</span>
                    </v-card-title>
                    <v-card-text>
                      <p
                        v-if="m.post_type === 'selling'"
                        class="text--primary text-base"
                      >
                        <span class="font-weight-bold"
                          >{{ m.amount }} {{ m.metric }}</span
                        >
                        <br />
                        <span class="text-caption"
                          >{{ m.type }} // {{ m.subtype }}</span
                        >
                        <br />
                        <span class="font-weight-bold info--text"
                          >${{ m.price }}</span
                        >
                      </p>
                      <p v-else class="text--primary text-base">
                        <span class="text-caption"
                          >{{ m.type }} // {{ m.subtype }}</span
                        >
                        <br />
                        <span v-if="m.public_address">{{
                          m.formatted_address
                        }}</span>
                      </p>
                      <p>
                        <span
                          v-if="
                            m.preferred_contact === 'phone' ||
                            m.preferred_contact === 'both'
                          "
                          class="font-weight-bold info--text"
                          >{{ m.phone }}</span
                        >
                        <span
                          v-if="
                            m.preferred_contact === 'email' ||
                            m.preferred_contact === 'both'
                          "
                          class="font-weight-bold info--text"
                          >{{ m.email }}</span
                        >
                      </p>
                      {{ m.description }}
                    </v-card-text>
                    <!--<v-card-actions
                      class="d-flex justify-space-around dense"
                    >
                      <v-btn
                        icon
                        color="error"
                        :disabled="!m.pit && (m.images === undefined || m.images === null || m.images.length <= 0)"
                        @click="loadImages(m)"
                      >
                        <v-icon>{{ icons.mdiImageMultiple }}</v-icon>
                      </v-btn>
                    </v-card-actions>-->
                  </v-card>
                </div>
              </GMapInfoWindow>
            </GMapMarker>
          </GMapMap>
        </v-col>
      
      
      </v-row>

    <div v-if="!loading" class="mobileDisplay">
    <div style="width:100%;overflow: hidden;background:#fff;display:flex;">
     <div  style="width:100%; position:absolute;bottom:0px;justify-content:center;display:flex; background:#fff; height:72%;"  v-if="modaltrue">

        <v-col
          id="material-boxes"
          md="6"
          sm="6"
          cols="12"
          class="scroll-column mt-6 elevation-0"
          style="padding: 10px 10px"
         
        >
          <v-container >
            <v-row>
              <v-col
                v-for="(mat, index) in materialData"
                :key="index"
                cols="12"
                sm="12"
                md="6"
              >
              
                <v-card
                  :key="index"
                  style="height: 280px; width:350px;"
                  :id="mat.id"
                  @click="relocateMap(mat)"
                >

                  <!-- <v-icon style="color:red">
              {{ icons.mdiMapMarker }}
            </v-icon> -->
                  <span
                    :style="{
                      color:
                        mat.post_type === 'selling'
                          ? 'red'
                          : !mat.pit && mat.post_type !== 'selling'
                          ? 'green'
                          : 'grey',
                      fontSize: '22px',
                      fontWeight: '500',
                      position: 'absolute',
                      top: 0,
                      marginLeft: '5px',
                    }"
                    >●</span
                  >
                  <!-- <div class="image-gallery" v-if="(mat.images !== undefined && mat.images !== null && mat.images.length > 0)" @click="loadImages(mat)">
                <img class="picimg" :src="mat.images[0]" />
              </div> -->
                  <v-card-title
                    v-if="mat.pit"
                    class="custom-title"
                    style="display: block"
                  >
                    <a
                      @click="openPitURL(mat)"
                      style=" color: black;font-size:18px; font-weight-bold"
                      >{{ mat.name }}</a
                    >
                  </v-card-title>
                  <v-card-title
                    v-else
                    style="display: flex; padding-bottom: 10px"
                    class="custom-title"
                  >
                    <a
                      style=" white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;color: black; font-size:18px; font-weight-bold"
                      >{{ mat.title }}</a
                    ><br />
                  </v-card-title>
                  <v-card-text v-if="mat.pit">
                    <div class="text-base">
                      <div style="display: flex">
                        <span
                          class=""
                          style="
                            color: black;
                            font-size: 14px;
                            margin-right: 5px;
                          "
                          >Address:
                        </span>
                        <span
                          class="text-caption"
                          style="
                            font-size: 14px;
                            color: grey;
                            margin-top: 2px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                          "
                          >{{ mat.formatted_address }}</span
                        >
                      </div>

                      <br />
                    </div>
                    {{ mat.description }}
                  </v-card-text>
                  <v-card-text v-else>
                    <div
                      v-if="mat.post_type === 'selling'"
                      class="text--primary text-base"
                    >
                      <div style="margin-bottom:3px;">
                        <span
                          class=""
                          style="
                            display: inline-block;
                            color: black;
                            font-size: 14px;
                            margin-right: 5px;
                          "
                          >Size:
                        </span>
                        <span
                          class="text-caption"
                          style="
                            display: inline-block;
                            font-size: 14px;
                            color: grey;
                          "
                          >{{ mat.amount }} {{ mat.metric }}</span
                        >
                      </div>
                      <div style="margin-bottom:3px;">
                        <span
                          class=""
                          style="
                            display: inline-block;
                            color: black;
                            font-size: 14px;
                            margin-right: 5px;
                          "
                          >Type:
                        </span>
                        <span
                          class="text-caption"
                          style="
                            display: inline-block;
                            color: grey;
                            font-size: 14px;
                          "
                          >{{ mat.type }} // {{ mat.subtype }}</span
                        >
                      </div>
                      <div style="margin-bottom:3px;">
                        <span
                          class=""
                          style="
                            display: inline-block;
                            color: black;
                            font-size: 14px;
                            margin-right: 5px;
                          "
                          >Price:
                        </span>
                        <span
                          class="text-caption"
                          style="
                            display: inline-block;
                            color: grey;
                            font-size: 14px;
                          "
                          >${{ mat.price }}</span
                        >
                      </div>
                    </div>
                    <div v-else class="text--primary text-base">
                      <div style="margin-bottom: 3px">
                        <span
                          class=""
                          style="
                            display: inline-block;
                            color: black;
                            font-size: 14px;
                            margin-right: 5px;
                          "
                          >Size:
                        </span>
                        <span
                          class="text-caption"
                          style="
                            display: inline-block;
                            font-size: 14px;
                            color: grey;
                          "
                          >{{ mat.amount }} {{ mat.metric }}</span
                        >
                      </div>
                      <div style="margin-bottom: 3px">
                        <span
                          class=""
                          style="
                            display: inline-block;
                            color: black;
                            font-size: 14px;
                            margin-right: 5px;
                          "
                          >Type:
                        </span>
                        <span
                          class="text-caption"
                          style="
                            display: inline-block;
                            color: grey;
                            font-size: 14px;
                          "
                          >{{ mat.type }} // {{ mat.subtype }}</span
                        >
                      </div>
                    </div>
                    <span
                      v-if="mat.public_address"
                      style="color: black; display: block; margin: 0px 0 10px 0"
                      >{{ mat.formatted_address }}</span
                    >
                    <!-- <span
                      v-if="
                        mat.preferred_contact === 'phone' ||
                        mat.preferred_contact === 'both'
                      "
                      style="color: black; display: block"
                      >Phone: {{ mat.phone }}</span
                    > -->
                    <!-- <span
                      v-if="
                        mat.preferred_contact === 'email' ||
                        mat.preferred_contact === 'both'
                      "
                      style="color: black; display: block"
                      >Email:
                      <a :href="`mailto:${mat.email}`">{{ mat.email }}</a></span
                    > -->
                    {{ mat.description }}
                  </v-card-text>
                  <div
                  :key="index"
                  @click="callPhoneNumber(mat.phone)"
                  @mouseenter="changeText(mat.phone)" @mouseleave="resetText(`Contact`)"
                    style="
                      background-color: #f0f0f0;
                      padding: 8px;
                      border-radius: 4px;
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      position: absolute;
                      bottom: 0;
                      width: 100%;
                    "
                  >
                    <div >
                      <span
                      
                        class=""
                        style="
                          display: inline-block;
                          color: black;
                          font-size: 14px;
                          margin-right: 5px;
                        "
                        >{{mat.phone===originalText?originalText:"Contact" }}
                      </span>
                      <!-- <span
                        class="text-caption"
                        style="
                          display: inline-block;
                          color: black;
                          font-size: 14px;
                        "
                        >{{ originalText||"" }}</span
                      > -->
                    </div>
                  </div>
                  
                 <div  class="container1">
                       <span
                          class="text-caption flex-starts"
                           style="align-self-start;bottom:0;position:absolue;"
                          >{{getTheCity(mat.address)}}</span>
                  </div>
                  <div class="container">
                
                    <span
                      style="align-self-end;"
                      class="text-caption flex-end"
                      >{{ humanReadable(mat.published_on) }}</span
                    >
                  </div>

                  <!--<v-card-actions
              class="d-flex justify-space-around dense"
            >
              <v-btn
                icon
                @click="relocateMap(mat)"
              >
                <img
                  v-if="mat.pit"
                  src="@/assets/images/misc/location-pit.png"
                  height="25"
                />
                <img
                  v-if="!mat.pit && mat.post_type === 'selling'"
                  src="@/assets/images/misc/location-sell.png"
                  height="25"
                />
                <img
                  v-if="!mat.pit && mat.post_type === 'looking'"
                  src="@/assets/images/misc/location-take.png"
                  height="25"
                />
              </v-btn>
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-if="mat.pit"
                    icon
                    color="success"
                    @click="openPitURL(mat)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>{{ icons.mdiWeb }}</v-icon>
                  </v-btn>
                </template>
                <span>Click here for website</span>
              </v-tooltip>


              <v-btn
                icon
                color="error"
                :disabled="!mat.pit && (mat.images === undefined || mat.images === null || mat.images.length <= 0)"
                @click="loadImages(mat)"
              >
                <v-icon>{{ icons.mdiImageMultiple }}</v-icon>
              </v-btn>
            </v-card-actions>-->
                </v-card>
              </v-col>
           
            </v-row>

           
          </v-container>
        </v-col>

       
 </div>

    </div>
    
</div>

      <!-- end........... -->

      <v-row :class="{ 'mb-10': mobileView }" v-if="!loading">
        <v-col cols="12" class="text-center" style="color: black">
          <h2>SOLVING ALL YOUR MATERIAL NEEDS!</h2>
        </v-col>
      </v-row>
    </v-col>
  
    
  </v-row>
   <div v-if="!loading" class="mobileDisplay">
  
  <div v-if="modaltrue"     style="display:flex;justify-content:center;" > 
       <div style="height:50px;width:120px;align-self:center; position:fixed;bottom:50px;z-index:1000">
                    <v-btn
            x-large
           color="#000"
            dark
            class="btn-hero "
           @click="toggleModalValue" 
            style="border-color: black !important; border-radius: 10px;  text-transform: unset !important;color:white;"
          >
                    <v-icon style="width:20px;height:20px;margin-left:-8px;margin-right:8px" dark right>
              {{ icons.mdiMapMarker }}
            </v-icon>
           Map
  
          </v-btn>
            </div>
  </div>
 
       <div @click="toggleModalValue"  v-if="!modaltrue" style="height:90px;width:100%;overflow:hidden; background:#fff; border-radius:20px; align-self:center; position:fixed;bottom:-20px; border:1px solid #f3f3f3;z-index:1000">
       <div  style="display:flex;justify-content:center;" > 
            <div style="justify-content:center;align-self:center;border-radius:15px; border:4px solid #000;width:80px;margin-top:30px;"/>
            </div>
       </div>

   </div>
</div>
  
</template>
<script>
import { initialAbility } from "@/plugins/acl/config";
import { useRouter } from "@core/utils";
import store from "@/store";
import useVuetify from "@core/utils/vuetify";
import {
  mdiChevronLeft,
  mdiChevronRight,
  mdiFilterVariant,
  mdiImageMultiple,
  mdiMapMarker,
  mdiWeb,
  mdiLogin,
  mdiAccountCircle,
  mdiLogout,
  mdiPlus,
} from "@mdi/js";
import { getCurrentInstance, onUnmounted, onMounted, ref, watch } from "vue";
import moment from "moment";
import GMapInfoWindow from "vue3-google-maps/dist/components/info-window.vue";
import GMapMap from "vue3-google-maps/dist/components/map.vue";
import GMapMarker from "vue3-google-maps/dist/components/marker";
import materialStoreModule from "./apps/material/materialStoreModule";
import authuserStoreModule from "./apps/authuser/authuserStoreModule";

export default {
  components: {
    GMapMap,
    GMapMarker,
    GMapInfoWindow,
  },
  setup() {
    const { rootThemeClasses } = useVuetify();
    const MATERIAL_APP_STORE_MODULE_NAME = "app-material";
    const AUTHUSER_APP_STORE_MODULE_NAME = "app-authuser";

    // Register module
    if (!store.hasModule(AUTHUSER_APP_STORE_MODULE_NAME)) {
      store.registerModule(AUTHUSER_APP_STORE_MODULE_NAME, authuserStoreModule);
    }

    // Register module
    if (!store.hasModule(MATERIAL_APP_STORE_MODULE_NAME)) {
      store.registerModule(MATERIAL_APP_STORE_MODULE_NAME, materialStoreModule);
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MATERIAL_APP_STORE_MODULE_NAME))
        store.unregisterModule(MATERIAL_APP_STORE_MODULE_NAME);
    });

    // onMounted
    onMounted(() => {
      window.addEventListener("resize", handleResize);
    });

    const { router } = useRouter();
    const vm = getCurrentInstance().proxy;

    const userData = JSON.parse(localStorage.getItem("userData"));
    const userRole = JSON.parse(localStorage.getItem("userAbility"));

    const loggedIn = ref(false);
    const modaltrue=ref(true);
    const mainMap = ref(null);
    const canadaWide = ref(null);
    const zoom = ref(10);
      const mobzoom = ref(10);
    const materialData = ref([]);
    const materialMarkers = ref([]);
    const pitMarkers = ref([]);
    const originalText = ref("Contact");
    const center = ref(null);
    const loading = ref(true);
    const markerSelected = ref(null);
    const imageDialogVisible = ref(false);
    const materialPicked = ref(null);
    const picIndex = ref(0);
    const searchAction = ref(null);
    const searchType = ref(null);
    const searchSubtype = ref(null);
    const subitemTypes = ref([]);
    const keyword = ref(null);
    const searchMetricType = ref(null);
    const searchTruckingType = ref(null);
    const searchMachineType = ref(null);

    const filterBox = ref(false);
    const materialResults = ref([]);
    const searching = ref(false);
    const select = ref(null);
    const materialSearch = ref(null);

    const searchMaterials = ref([]);
    const mobileView = ref(false);

    const metricTypes = [
      {
        itemType: "",
        description: "Reset",
      },
      {
        itemType: "tons",
        description: "Tons",
      },
      {
        itemType: "yards",
        description: "Yards",
      },
      /*{
        itemType: 'yards_cubed',
        description: 'Yards Cubed',
      },*/
      {
        itemType: "meters",
        description: "Meters",
      },
    ];

    const truckOptions = [
      {
        itemType: "",
        description: "Reset",
      },
      {
        itemType: "truck_available",
        description: "I have a truck available to deliver material",
      },
      {
        itemType: "truck_required",
        description: "I require a truck to deliver material",
      },
    ];

    const machineOptions = [
      {
        itemType: "",
        description: "Reset",
      },
      {
        itemType: "machine_available",
        description: "I have a machine on site to load material",
      },
      {
        itemType: "machine_required",
        description: "I do not have a machine on site to load the material",
      },
    ];

    const dutMaterial = ref([
      { description: "Show all", post_type: "all" },
      { description: "Source dirt", post_type: "selling" },
      { description: "Give away dirt", post_type: "looking" },
      { description: "Listing", post_type: "Listing" },
    ]);
    const itemTypes = ref([
      {
        itemType: "",
        description: "Reset",
      },
      {
        itemType: "Asphalt",
        description: "Asphalt",
        image: require("@/assets/images/misc/materials/asphalt.jpeg"),
      },
      {
        description: "--- Recycled ashphalt",
        itemSubtype: "Recycled ashphalt",
        image: require("@/assets/images/misc/materials/recycled-asphalt.png"),
      },
      /*{
        itemType: 'Assorted mixed material',
        description: 'Assorted mixed material',
      },
      {
        description: '--- Contaminated mixed material',
        itemSubType: 'Contaminated mixed material',
        image: '',
      },*/
      {
        itemType: "Bark mulch",
        description: "Bark mulch",
        image: require("@/assets/images/misc/materials/bark-mulch.png"),
      },
      {
        itemType: "Clay",
        description: "Clay",
        image: require("@/assets/images/misc/materials/clay.png"),
      },
      {
        description: "--- Clean Clay",
        itemSubtype: "Clean Clay",
        image: require("@/assets/images/misc/materials/clay.png"),
      },
      {
        description: "--- Dirty contaminated clay material",
        itemSubtype: "Dirty contaminated clay material",
        image: require("@/assets/images/misc/materials/contaminated-clay.png"),
      },
      {
        description: "--- Medium contaminated clay material",
        itemSubtype: "Medium contaminated clay material",
        image: require("@/assets/images/misc/materials/contaminated-clay.png"),
      },
      {
        itemType: "Concrete",
        description: "Concrete",
        image: require("@/assets/images/misc/materials/concrete-rebar.png"),
      },
      {
        description: "--- Concrete with rebar",
        itemSubtype: "Concrete with rebar",
        image: require("@/assets/images/misc/materials/concrete-rebar.png"),
      },
      {
        description: "--- Concrete without rebar",
        itemSubtype: "Concrete without rebar",
        image: require("@/assets/images/misc/materials/concrete-wo-rebar.png"),
      },
      {
        itemType: "Fill material",
        description: "Fill material",
        image: require("@/assets/images/misc/materials/clean-fill-material.png"),
      },
      {
        description: "--- Clean fill material",
        itemSubtype: "Clean fill material",
        image: require("@/assets/images/misc/materials/clean-fill-material.png"),
      },
      {
        description: "--- Dirty contaminated fill material",
        itemSubtype: "Dirty contaminated fill material",
        image: require("@/assets/images/misc/materials/dirty-fill-material.jpeg"),
      },
      {
        description: "--- Medium contaminated fill material",
        itemSubtype: "Medium contaminated fill material",
        image: require("@/assets/images/misc/materials/medium-fill-material.jpeg"),
      },
      {
        itemType: "Fractured clear rock",
        description: "Fractured clear rock",
        image: require("@/assets/images/misc/materials/fractured-clear-rock-with-2-or-more-flat-sides.png"),
      },
      {
        description: "--- 12mm clear fractured stone",
        itemSubtype: "12mm clear fractured stone",
        image: require("@/assets/images/misc/materials/12mm-fractured-stone.png"),
      },
      {
        description: "--- 16mm clear fractured stone",
        itemSubtype: "16mm clear fractured stone",
        image: require("@/assets/images/misc/materials/16mm-fractured-stone.png"),
      },
      {
        description: "--- 25mm clear fractured stone",
        itemSubtype: "25mm clear fractured stone",
        image: require("@/assets/images/misc/materials/25mm-fractured-stone.png"),
      },
      {
        description: "--- 50mm clear fractured stone",
        itemSubtype: "50mm clear fractured stone",
        image: require("@/assets/images/misc/materials/50mm-fractured-stone.png"),
      },
      {
        description: "--- 75mm clear fractured stone",
        itemSubtype: "75mm clear fractured stone",
        image: require("@/assets/images/misc/materials/75mm-fractured-stone.png"),
      },
      {
        description: "--- 75-150mm clear fractured stone",
        itemSubtype: "75-150mm clear fractured stone",
        image: require("@/assets/images/misc/materials/75to150mm-fractured-stone.png"),
      },
      {
        description: "--- Rip wrap - Large, fractured stone",
        itemSubtype: "Rip wrap - Large, fractured stone",
        image: require("@/assets/images/misc/materials/rip-wrap-fractured-stone.png"),
      },
      {
        description: "--- Wall Rock  with 2 or more flat sides",
        itemSubtype: "Wall Rock  with 2 or more flat sides",
        image: require("@/assets/images/misc/materials/fractured-clear-rock-with-2-or-more-flat-sides.png"),
      },
      {
        itemType: "Fractured rock compactible",
        description: "Fractured rock compactible",
        image: require("@/assets/images/misc/materials/fractured-rock-compactible-blast-bank.png"),
      },
      {
        description: "--- 6mm Crusher Chip",
        itemSubtype: "6mm Crusher Chip",
        image: require("@/assets/images/misc/materials/fractured-rock-compactable-6mm.png"),
      },
      {
        description: "--- 25mm Road Base (Compactable) Rock",
        itemSubtype: "25mm Road Base (Compactable) Rock",
        image: require("@/assets/images/misc/materials/fractured-rock-compactible-25mm.png"),
      },
      {
        description: "--- 75mm Road Base (Compactable) Rock",
        itemSubtype: "75mm Road Base (Compactable) Rock",
        image: require("@/assets/images/misc/materials/fractured-rock-compactible-75mm.png"),
      },
      {
        description: "--- 150mm Road Base (Compactable) Rock",
        itemSubtype: "150mm Road Base (Compactable) Rock",
        image: require("@/assets/images/misc/materials/fractured-rock-compactible-150mm.png"),
      },
      {
        description: "--- Blast Bank Run Fractured Stone",
        itemSubtype: "Blast Bank Run Fractured Stone",
        image: require("@/assets/images/misc/materials/fractured-rock-compactible-blast-bank.png"),
      },
      {
        itemType: "Lawn / Sod",
        description: "Lawn / Sod",
        image: require("@/assets/images/misc/materials/clean-sod.png"),
      },
      {
        description: "--- Contaminated Sod material",
        itemSubtype: "Contaminated Sod material",
        image: require("@/assets/images/misc/materials/dirty-contaminated-sod.png"),
      },
      {
        itemType: "Natural stone",
        description: "Natural stone",
        image: require("@/assets/images/misc/materials/larger-natural-stone.png"),
      },
      {
        description: "--- 5-10mm clear natural stone",
        itemSubtype: "5-10mm clear natural stone",
        image: require("@/assets/images/misc/materials/5to10mm-natural-stone.png"),
      },
      {
        description: "--- 5-14mm clear natural stone",
        itemSubtype: "5-14mm clear natural stone",
        image: require("@/assets/images/misc/materials/5to14mm-natural-stone.png"),
      },
      {
        description: "--- 25mm clear natural stone",
        itemSubtype: "25mm clear natural stone",
        image: require("@/assets/images/misc/materials/25mm-natural-stone.png"),
      },
      {
        description: "--- 50mm clear natural stone",
        itemSubtype: "50mm clear natural stone",
        image: require("@/assets/images/misc/materials/50mm-natural-stone.png"),
      },
      {
        description: "--- 75-150mm Clear Natural Stone",
        itemSubtype: "75-150mm Clear Natural Stone",
        image: require("@/assets/images/misc/materials/75to150mm-natural-stone.png"),
      },
      {
        description: "--- Larger natural stone",
        itemSubtype: "Larger natural stone",
        image: require("@/assets/images/misc/materials/larger-natural-stone.png"),
      },
      {
        itemType: "Ogo grow",
        description: "Ogo grow",
        image: require("@/assets/images/misc/materials/ogo-grow.jpeg"),
      },
      {
        description: "--- Clean Ogo Grow",
        itemSubtype: "Clean Ogo Grow",
        image: require("@/assets/images/misc/materials/ogo-grow.jpeg"),
      },
      {
        description: "--- Contaminated Ogo Grow",
        itemSubtype: "Contaminated Ogo Grow",
        image: require("@/assets/images/misc/materials/ogo-grow-contaminated.jpeg"),
        image: "",
      },
      {
        itemType: "Pit run",
        description: "Pit run",
        image: require("@/assets/images/misc/materials/3-pit-run.jpeg"),
      },
      {
        description: '--- 3" minus pit run',
        itemSubType: '3" minus pit run',
        image: require("@/assets/images/misc/materials/3-pit-run.jpeg"),
      },
      {
        description: '--- 6" minus pit run',
        itemSubType: '6" minus pit run',
        image: require("@/assets/images/misc/materials/6-pit-run.png"),
      },
      /*{
        description: '--- Oversized pit run',
        itemSubType: 'Oversized pit run',
        image: '',
      },*/
      {
        itemType: "Sand",
        description: "Sand",
        image: require("@/assets/images/misc/materials/natural-sand.jpeg"),
      },
      {
        description: "--- 3mm City spec manufactured sand",
        itemSubType: "3mm City spec manufactured sand",
        image: require("@/assets/images/misc/materials/3-city-sand.png"),
      },
      {
        description: "--- 3mm Eco manufactured bedding sand",
        itemSubType: "3mm Eco manufactured bedding sand",
        image: require("@/assets/images/misc/materials/3-eco-sand.png"),
      },
      {
        description: "--- 5mm natural sand",
        itemSubType: "5mm natural sand",
        image: require("@/assets/images/misc/materials/5-natural-sand.jpeg"),
      },
      {
        description: "--- 5mm natural sand, blended with 5% salt",
        itemSubType: "5mm natural sand, blended with 5% salt",
        image: require("@/assets/images/misc/materials/5-sand-salt-sand.png"),
      },
      {
        description: "--- C-33 Septic Sand",
        itemSubType: "C-33 Septic Sand",
        image: require("@/assets/images/misc/materials/c-33-septic-sand.png"),
      },
      {
        description: "--- Hydro spec bedding sand",
        itemSubType: "Hydro spec bedding sand",
        image: require("@/assets/images/misc/materials/hydro-sand.png"),
      },
      {
        description: "--- Natural sand",
        itemSubType: "Natural sand",
        image: require("@/assets/images/misc/materials/natural-sand.jpeg"),
      },
      {
        itemType: "Top Soil",
        description: "Top soil",
        image: require("@/assets/images/misc/materials/topsoil.jpeg"),
      },
      {
        description: "--- Contaminated Top Soil material",
        itemSubtype: "Contaminated Top Soil material",
        image: require("@/assets/images/misc/materials/topsoil-contaminated.png"),
      },
      {
        itemType: "Trees",
        description: "Trees",
        image: require("@/assets/images/misc/materials/trees-stumps-with-roots.jpeg"),
      },
      {
        description: "--- Brush",
        itemSubtype: "Brush",
        image: require("@/assets/images/misc/materials/trees-brush.png"),
      },
      {
        description: "--- Tree limbs",
        itemSubtype: "Tree limbs",
        image: require("@/assets/images/misc/materials/trees-limbs.png"),
      },
      {
        description: "--- Tree Stumps",
        itemSubtype: "Tree Stumps",
        image: require("@/assets/images/misc/materials/trees-stumps.png"),
      },
      {
        description: "--- Tree Stumps with roots",
        itemSubtype: "Tree Stumps with roots",
        image: require("@/assets/images/misc/materials/trees-stumps-with-roots.jpeg"),
      },
      {
        itemType: "Wood",
        description: "Wood",
        image: require("@/assets/images/misc/materials/wood.png"),
      },
      {
        itemType: "Other",
        description: "Other",
        image: "",
      },
    ]);

    const actionTypes = [
      { description: "Reset", post_type: "all" },
      { description: "Getting Rid of Material", post_type: "selling" },
      { description: "Taking Material", post_type: "looking" },
    ];

    const searchProximity = ref(250);
    const searchPrice = ref([0, 2500]);
    const searchWeight = ref([0, 100]);

    const handleResize = () => {
      const el = document.getElementById("main-logo");
      const elBox = document.getElementById("material-boxes");

      console.log(mainMap.value);
      console.log(canadaWide.value);

      if (el !== null) {
        if (window.innerWidth < 576) {
          el.classList.remove("hero-logo");
          el.classList.add("hero-logo-sm");

          canadaWide.value.classList.add("mobile");
          mainMap.value.$el.classList.add("order-1");
          elBox.classList.add("order-2");
          mobileView.value = true;
        } else {
          el.classList.remove("hero-logo-sm");
          el.classList.add("hero-logo");

          canadaWide.value.classList.remove("mobile");
          mainMap.value.$el.classList.remove("order-1");
          elBox.classList.remove("order-2");
          mobileView.value = false;
        }
      }
    };

    const sortMaterials = () => {
      materialData.value.sort((a, b) => {
        const obj1 = a.published_on.seconds;
        const obj2 = b.published_on.seconds;

        if (obj1 > obj2) return 1;
        if (obj2 > obj1) return -1;

        return 0;
      });
    };

    const addToMaterialMarkers = (_addtlMarkers) => {
      materialMarkers.value = materialMarkers.value.concat(_addtlMarkers);
    };

 const getTheCity = (address) => {
   if(address){
    const val= address.split(',')
    const vals= val[val.length-2]?.split(' ')
    let value=`Posted in ${vals[1]},${val[val.length-1]}`
    return value;
   }
   else{
     return address;
   }
    };


    const loadMaterialMarkers = async () => {
      const localMaterials = localStorage.getItem("published-materials");
      if (
        localMaterials &&
        localMaterials !== undefined &&
        localMaterials !== null
      ) {
        materialMarkers.value = JSON.parse(localMaterials);
        materialData.value = JSON.parse(localMaterials);
        sortMaterials();
      } else {
        await store
          .dispatch("app-material/fetchMarkers")
          .then((response) => {
            materialMarkers.value = response;
            materialData.value = response;
            localStorage.setItem(
              "published-materials",
              JSON.stringify(response)
            );
            sortMaterials();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    };

    const loadPitMarkers = async () => {
      const localPits = localStorage.getItem("published-pits");
      if (localPits && localPits !== undefined && localPits !== null) {
        pitMarkers.value = JSON.parse(localPits);
        materialData.value = materialData.value.concat(JSON.parse(localPits));
        loading.value = false;
        addToMaterialMarkers(JSON.parse(localPits));
      } else {
        await store
          .dispatch("app-material/fetchPitMarkers")
          .then((response) => {
            pitMarkers.value = response;
            materialData.value = materialData.value.concat(response);
            localStorage.setItem("published-pits", JSON.stringify(response));
            addToMaterialMarkers(response);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    };

    const loadTakeMaterialMarkers = async () => {
      const localSaleMaterials = localStorage.getItem(
        "published-take-materials"
      );
      if (
        localSaleMaterials &&
        localSaleMaterials !== undefined &&
        localSaleMaterials !== null
      ) {
        // pitMarkers.value = JSON.parse(localSaleMaterials)
        materialData.value = materialData.value.concat(
          JSON.parse(localSaleMaterials)
        );
        loading.value = false;
        addToMaterialMarkers(JSON.parse(localSaleMaterials));
      } else {
        await store
          .dispatch("app-material/fetchTakeMarkers")
          .then((response) => {
            // pitMarkers.value = response
            materialData.value = materialData.value.concat(response);
            localStorage.setItem(
              "published-take-materials",
              JSON.stringify(response)
            );
            addToMaterialMarkers(response);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    };

    const trunc70 = (str) => {
      if (str.length > 70) {
        return `${str.substr(0, 70)} ...`;
      }

      return str;
    };

    const loadImages = (material) => {
      imageDialogVisible.value = true;
      materialPicked.value = material;
    };

    const openPitURL = (material) => {
      window.open(material.website, "newtab");
    };

    const whichImage = () => {
      if (materialPicked.value.pit) {
        return materialPicked.value.logo_image;
      }

      return materialPicked.value.images[picIndex.value];
    };

    const prevImage = () => {
      if (picIndex.value > 0) {
        picIndex.value -= 1;
      }

      // whichImage()
    };

    const nextImage = () => {
      if (picIndex.value < materialPicked.value.images.length) {
        picIndex.value += 1;
      }

      // whichImage()
    };

    const closeDialog = () => {
      imageDialogVisible.value = false;
      materialPicked.value = null;
      picIndex.value = 0;
    };

    const relocateMap = (material) => {
      zoom.value = 14;
      mobzoom.value=14
      center.value = material.markerLocation;
      markerSelected.value = material;
    };

    const highlightSale = () => {
      materialMarkers.value.forEach((_m, idx) => {
        if (_m.post_type === "looking" || _m.pit) {
          materialMarkers.value[idx].highlight = false;
        } else {
          materialMarkers.value[idx].highlight = true;
        }
      });
    };

    const highlightTake = () => {
      materialMarkers.value.forEach((_m, idx) => {
        if (_m.post_type === "looking") {
          materialMarkers.value[idx].highlight = true;
        } else {
          materialMarkers.value[idx].highlight = false;
        }
      });
    };

    const getCustom = (material) => {
      const normSize = 40;
      if (
        (markerSelected.value && markerSelected.value.id === material.id) ||
        material.highlight
      ) {
        let defSize = 50;

        // Modify default size to bigger when highlighted
        if (material.highlight) {
          defSize = 65;
        }

        if (material.pit) {
          return {
            url: require("@/assets/images/misc/location-pit.png"),
            scaledSize: { width: defSize, height: defSize },
          };
        }
        if (material.post_type === "looking") {
          return {
            url: require("@/assets/images/misc/location-take.png"),
            scaledSize: { width: defSize, height: defSize },
          };
        }

        return {
          url: require("@/assets/images/misc/location-sell.png"),
          scaledSize: { width: defSize, height: defSize },
        };
      }

      if (material.pit) {
        return {
          url: require("@/assets/images/misc/location-pit.png"),
          scaledSize: { width: normSize, height: normSize },
        };
      }
      if (material.post_type === "looking") {
        return {
          url: require("@/assets/images/misc/location-take.png"),
          scaledSize: { width: normSize, height: normSize },
        };
      }

      return {
        url: require("@/assets/images/misc/location-sell.png"),
        scaledSize: { width: normSize, height: normSize },
      };
    };

    const markerPicked = (material) => {
      zoom.value = 14;
      mobzoom.value=14;
      center.value = material.markerLocation;
      markerSelected.value = material;
      getCustom(material);
      toggleModalValue();
    };
    const changeText = (value) => {
  originalText.value = value;
};

const resetText = (value) => {
  originalText.value = value;
};
const callPhoneNumber = (value) => {
  window.location.href = `tel:${value}`;
};

    const markerHover = (material) => {
      markerSelected.value = material;

      const el = document.getElementById(material.id);
      el.scrollIntoView();
      console.log(material);
      if (material.post_type === "selling") {
        el.classList.remove("sell-transluscent");
        el.classList.add("sell-darker");
      }
      if (material.post_type === "looking") {
        el.classList.remove("take-transluscent");
        el.classList.add("take-darker");
      }
      if (material.pit) {
        el.classList.remove("pit-transluscent");
        el.classList.add("pit-darker");
      }

      getCustom(material);
    };

    const markerClear = (material) => {
      const el = document.getElementById(markerSelected.value.id);
      if (markerSelected.value.post_type === "selling") {
        el.classList.add("sell-transluscent");
        el.classList.remove("sell-darker");
      }
      if (markerSelected.value.post_type === "looking") {
        el.classList.add("take-transluscent");
        el.classList.remove("take-darker");
      }
      if (markerSelected.value.pit) {
        el.classList.add("pit-transluscent");
        el.classList.remove("pit-darker");
      }

      markerSelected.value = null;
      getCustom(material);
    };

    const humanReadable = (dtobj) => {
      if (
        dtobj !== undefined &&
        dtobj !== null &&
        dtobj.seconds !== undefined
      ) {
        return moment(dtobj.seconds * 1000).format("MM/DD/yyyy hh:mma");
      }

      return dtobj;
    };

    const showProximityFilter = (proximityRange) => {
      return `${proximityRange} kms`;
    };

    const showPriceFilter = (priceRange) => {
      let out = "";
      if (priceRange[0] === 0) {
        out = "Free";
      } else {
        const min = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(priceRange[0]);
        out = `${min}`;
      }
      if (out.length > 0) {
        if (priceRange[1] === 10000) {
          out += " to ∞";
          if (priceRange[0] === 0) {
            out = "All";
          }
        } else {
          const max = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(priceRange[1]);
          out += ` to ${max}`;
        }
      }

      return out;
    };

    const showWeightFilter = (weightRange) => {
      const min = new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 3,
      }).format(weightRange[0]);
      let out = min;

      if (out.length > 0) {
        if (weightRange[1] === 100) {
          out += " to ∞";
          if (weightRange[0] === 0) {
            out = "All";
          }
        } else {
          const max = new Intl.NumberFormat("en-US", {
            maximumFractionDigits: 3,
          }).format(weightRange[1]);
          out += ` to ${max}`;
        }
      }

      return out;
    };

    const searchMaterial = (keywords) => {
      console.log(keywords);
    };

    const querySearch = (query) => {
      searching.value = true;

      // Simulated ajax query
      setTimeout(() => {
        materialResults.value = searchMaterials.value.filter((mat, idx) => {
          const found = mat.description
            .toLowerCase()
            .search(query.toLowerCase());
          let res = [];

          if (found > -1) {
            res.push(searchMaterials.value[found]);
          }
          return res;
        });
        searching.value = false;
      }, 500);
    };

    const selectedMaterial = () => {
      if (keyword.value !== undefined && keyword.value !== null) {
        // console.log(keyword, "key");
        // const selected = keyword.value.replace("--- ", "").toLowerCase();
        // const otherData = dutMaterial.value.find(
        //   (item) => item.description === keyword.value
        // );
        // console.log(otherData, "daraa");
        // //   const selected = keyword.value.replace("--- ", "").toLowerCase();
        // console.log(selected, "selected");
        // let searchResults = [];
        // if (otherData.post_type === "all") {
        //   searchResults = materialData.value;
        // } else if (otherData.post_type === "Listing") {
        //   searchResults = materialData.value.filter(
        //     (mat, idx) =>
        //       mat.post_type !== "selling" && mat.post_type !== "looking"
        //   );
        // } else {
        //   searchResults = materialData.value.filter(
        //     (mat, idx) => mat.post_type === otherData.post_type
        //   );
         const selected = keyword.value.replace("--- ", "").toLowerCase()
        console.log(selected)

        const searchResults = searchMaterials.value.filter((mat, idx) => {
          const titleFound = mat.description.toLowerCase().search(selected)
          console.log(titleFound)
          
          if (titleFound >= 0) {
            return true
        }

          return false
        })
        
        let results = [];
        searchResults.forEach((r, idx) => {
          const db = r.db.split("|");
          const materials = JSON.parse(localStorage.getItem(db[0]));
          const index = parseInt(db[1]);

          if (materials[index].markerLocation === undefined) {
            materials[index].markerLocation = {
              lat: materials[index].location.latitude,
              lng: materials[index].location.longitude,
            };
          }

          results.push(materials[index]);
        });
        console.log(searchResults, "results");

        // materialData.value = searchResults;
        // materialMarkers.value = searchResults;
        // if (searchResults.length > 0) relocateMap(searchResults[0]);
          materialData.value = results
        materialMarkers.value = results
        if (results.length > 0)
          relocateMap(results[0])
        /*
        const materials = JSON.parse(localStorage.getItem(selected[0]))
        const index = parseInt(selected[1])

        materialData.value = [ materials[index] ]
        materialMarkers.value = [ materials[index] ]

        relocateMap(materials[index])
        */
      } else {
        reloadMarkers(false);
      }
    };

    const selectedMaterialType = () => {};

    const doSearch = () => {
      const localMaterials = JSON.parse(
        localStorage.getItem("published-materials")
      );
      const localTakeMaterials = JSON.parse(
        localStorage.getItem("published-take-materials")
      );
      const searchMaterials = localMaterials.concat(localTakeMaterials);

      if (keyword.value.length >= 3) {
        materialData.value = searchMaterials.filter((mat, idx) => {
          console.log(idx);
          const titleFound = mat.title
            .toLowerCase()
            .search(keyword.value.toLowerCase());
          const typeFound = mat.type
            .toLowerCase()
            .search(keyword.value.toLowerCase());
          const subtypeFound = mat.subtype
            .toLowerCase()
            .search(keyword.value.toLowerCase());
          let descFound = -1;
          if (mat.description !== null && mat.description !== undefined) {
            descFound = mat.description
              .toLowerCase()
              .search(keyword.value.toLowerCase());
          }

          if (
            titleFound >= 0 ||
            typeFound >= 0 ||
            subtypeFound >= 0 ||
            descFound >= 0
          )
            return true;

          return false;
        });
      } else {
        materialData.value = localMaterials;
      }
    };

    const clearSearch = (type) => {
      if (type) {
        // const localMaterials = JSON.parse(localStorage.getItem('published-materials'))
        // materialMarkers.value = localMaterials
      }
    };

    const postAd = () => {
      if (!loggedIn.value) {
        goLogin();
      } else {
        // router.push('materials/add')
        location.href = "materials/add";
      }
    };

    const searchByAction = (actionIdx) => {
      if (!loggedIn.value) {
        goLogin();
      } else {
        searchAction.value = actionTypes[actionIdx];
        searchFilter();
      }
    };

    const searchFilter = () => {
      const pitMaterials = JSON.parse(localStorage.getItem("published-pits"));
      const localMaterials = JSON.parse(
        localStorage.getItem("published-materials")
      );
      const localTakeMaterials = JSON.parse(
        localStorage.getItem("published-take-materials")
      );

      const searchMaterials = localMaterials.concat(localTakeMaterials);
      const allMaterials = searchMaterials.concat(pitMaterials);

      // Search by Selling/Looking
      let byActionResults = allMaterials;
      if (searchAction.value && searchAction.value.post_type !== "all") {
        byActionResults = searchMaterials.filter((mat, idx) => {
          console.log(searchAction.value.post_type);
          const postTypeFound = mat.post_type
            .toLowerCase()
            .search(searchAction.value.post_type.toLowerCase());

          if (postTypeFound >= 0) return true;

          return false;
        });
      }

      console.log(byActionResults);
      // Search by Type
      let byTypeResults = byActionResults;
      console.log(searchType.value);
      if (searchType.value) {
        if (searchType.value.itemType === "") {
          subitemTypes.value = [];
          searchSubtype.value = null;
        } else {
          if (
            searchType.value.description === undefined &&
            searchType.value.length > 0
          ) {
            const byIndex = itemTypes.findIndex(
              (el) => el.description === searchType.value
            );
            if (byIndex >= 0) {
              searchType.value = itemTypes[byIndex];
              console.log(searchType.value);
            }
          }
          byTypeResults = byActionResults.filter((mat, idx) => {
            console.log(idx);
            if (searchType.value.itemType !== undefined && mat.type) {
              const postTypeFound = mat.type
                .toLowerCase()
                .search(searchType.value.itemType.toLowerCase());

              if (postTypeFound >= 0) return true;
            }
            if (
              searchType.value.itemSubtype !== undefined &&
              mat.type &&
              mat.subtype
            ) {
              const postSubTypeFound = mat.subtype
                .toLowerCase()
                .search(searchType.value.itemSubtype.toLowerCase());

              if (postSubTypeFound >= 0) return true;
            }

            return false;
          });
          // subitemTypes.value = itemSubTypes[searchType.value.itemType]
        }
      }

      // console.log(byTypeResults)
      // Search by SubType
      /*let bySubtypeResults = byTypeResults
      if (searchSubtype.value && searchSubtype.value.itemSubtype !== 'Reset') {
        bySubtypeResults = byTypeResults.filter((mat, idx) => {
          console.log(idx)
          if (mat.subtype) {
            const postSubTypeFound = mat.subtype.toLowerCase().search(searchSubtype.value.itemSubtype.toLowerCase())

            if (postSubTypeFound >= 0) return true
          }

          return false
        })
      }*/

      // console.log(bySubtypeResults)
      // Search by Price
      //let byPrice = bySubtypeResults
      console.log(byTypeResults);
      let byPrice = byTypeResults;
      if (
        searchPrice.value &&
        (searchPrice.value[0] > 0 || searchPrice.value[1] < 2500)
      ) {
        byPrice = byTypeResults.filter((mat, idx) => {
          console.log(idx);
          if (mat.price) {
            if (
              mat.price >= searchPrice.value[0] &&
              mat.price <= searchPrice.value[1]
            ) {
              return true;
            }
          } else {
            if (mat.post_type === "looking") {
              return true;
            }
          }

          return false;
        });
      }

      // Search by Amount/Weight
      console.log(byPrice);
      let byAmount = byPrice;
      if (
        searchWeight.value &&
        searchMetricType.value &&
        (searchWeight.value[0] > 0 || searchWeight.value[1] < 100)
      ) {
        byAmount = byPrice.filter((mat, idx) => {
          console.log(idx);
          if (mat.amount) {
            if (
              mat.amount >= searchWeight.value[0] &&
              mat.amount <= searchWeight.value[1]
            ) {
              if (mat.metric === searchMetricType.value.itemType) {
                return true;
              }
            }
          }

          return false;
        });
      }

      // Final result
      materialData.value = byAmount;
      materialMarkers.value = byAmount;
    };

    const resetFilter = () => {
      searchAction.value = null;
      searchType.value = null;
      searchSubtype.value = null;
      searchPrice.value = [0, 10000];
      searchWeight.value = [0, 100];
      searchMetricType.value = null;
      searchTruckingType.value = null;
      searchMachineType.value = null;
    };

    const toggleFilterDialog = () => {
      if (filterBox.value) {
        filterBox.value = false;
        searchFilter();
      } else {
        filterBox.value = true;
      }
    };

    const goLogin = () => {
      //router.push('/login')
      location.href = "/login";
    };

    const goRegister = () => {
      // router.push('/register')
      location.href = "/register";
    };
    const   openLink=()=> {
      window.open("https://www.materialtrader.ca/", '_blank'); // Opens the link in a new tab/window
      // Alternatively, you can use the following to open the link in the same window:
      // window.location.href = this.linkUrl;
    }

    const goLogout = async () => {
      // Remove userData from localStorage
      // ? We just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem("accessToken");

      // Remove userData & Ability from localStorage
      localStorage.removeItem("userData");
      localStorage.removeItem("userAbility");
      localStorage.removeItem("materials-list");
      localStorage.removeItem("published-materials");
      localStorage.removeItem("published-take-materials");

      // Reset ability
      vm.$ability.update(initialAbility);

      await store.dispatch("app-authuser/logOut").then(() => {
        // Redirect to login page
        location.reload();
      });
    };

    const goMyAccount = () => {
      router.push("/dashboards");
    };

    const reloadMarkers = (init) => {
      searchMaterials.value = [];
      let lastLoad = localStorage.getItem("last-loaded");

      if (lastLoad === null) {
        localStorage.removeItem("published-materials");
        localStorage.removeItem("published-take-materials");
      } else {
        const now = Date.now();
        const diffTime = (now - lastLoad) / 1000;
        const hours = diffTime / 3600;
        // Clear and refresh localstorage every 3 hours
        if (hours >= 3) {
          localStorage.removeItem("last-loaded");
          localStorage.removeItem("published-materials");
          localStorage.removeItem("published-take-materials");
          lastLoad = null;
        }
      }

      loadMaterialMarkers().then(() => {
        const localMaterials = JSON.parse(
          localStorage.getItem("published-materials")
        );
        if (localMaterials !== null) {
          localMaterials.forEach((mat, index) => {
            const material = {
              title: `${mat.title} - ${mat.type} / ${mat.subtype}`,
              description: `${mat.title.toLowerCase()} ${mat.type.toLowerCase()} ${mat.subtype.toLowerCase()}`,
              db: `published-materials|${index}`,
            };
            searchMaterials.value.push(material);
            if (mat.type === "Other") {
              const findOtherSubIdx = itemTypes.value.findIndex(
                (el) => el.itemSubtype === mat.subtype
              );
              console.log(findOtherSubIdx);
              if (findOtherSubIdx < 0) {
                itemTypes.value.push({
                  itemType: "Other",
                  description: `--- ${mat.subtype}`,
                  image: "",
                  itemSubtype: mat.subtype,
                });
              }
            }
          });
        }

        loadTakeMaterialMarkers().then(() => {
          const localTakeMaterials = JSON.parse(
            localStorage.getItem("published-take-materials")
          );
          if (localTakeMaterials !== null) {
            localTakeMaterials.forEach((mat, index) => {
              const material = {
                title: `${mat.title} - ${mat.type} / ${mat.subtype}`,
                description: `${mat.title.toLowerCase()} ${mat.type.toLowerCase()} ${mat.subtype.toLowerCase()}`,
                db: `published-take-materials|${index}`,
              };
              searchMaterials.value.push(material);
              if (mat.type === "Other") {
                const findOtherSubIdx = itemTypes.value.findIndex(
                  (el) => el.itemSubtype === mat.subtype
                );
                if (findOtherSubIdx < 0) {
                  itemTypes.value.push({
                    itemType: "Other",
                    description: `--- ${mat.subtype}`,
                    image: "",
                    itemSubtype: mat.subtype,
                  });
                }
              }
            });
          }

          loadPitMarkers().then(() => {
            loading.value = false;
            if (init) {
              // Subscribe
              store.dispatch("app-material/subToMaterials", modifiedData);
              // Resize
              handleResize();
            }
            if (lastLoad === null) {
              // Store last Time it was synced
              localStorage.setItem("last-loaded", Date.now());
            }
          });
        });
      });
    };

    const modifiedData = (type, document) => {
      const localMaterials = JSON.parse(
        localStorage.getItem("published-materials")
      );
      const localTakeMaterials = JSON.parse(
        localStorage.getItem("published-take-materials")
      );

      let indexOfM = -1;
      let indexOfT = -1;

      if (type == "modified") {
        console.log(document.id);
        const docData = document.data();
        indexOfM = localMaterials.findIndex(
          (element) => element.id === document.id
        );
        console.log(indexOfM);
        if (indexOfM >= 0) {
          localMaterials[indexOfM] = docData;
          localMaterials[indexOfM]["id"] = document.id;
          // Remove if material is unpublished or sold
          if (!docData["published"] || docData["sold"] || docData["deleted"]) {
            localMaterials.splice(indexOfM, 1);
          }
          localStorage.setItem(
            "published-materials",
            JSON.stringify(localMaterials)
          );
          reloadMarkers(false);
        } else {
          indexOfT = localTakeMaterials.findIndex(
            (element) => element.id === document.id
          );
          if (indexOfT >= 0) {
            localTakeMaterials[indexOfT] = docData;
            localTakeMaterials[indexOfT]["id"] = document.id;
            // Remove if material is unpublished or sold
            if (
              !docData["published"] ||
              docData["sold"] ||
              docData["deleted"]
            ) {
              localTakeMaterials.splice(indexOfT, 1);
            }
            localStorage.setItem(
              "published-take-materials",
              JSON.stringify(localTakeMaterials)
            );
            reloadMarkers(false);
          } else {
            // Need to insert
            if (indexOfM < 0 && indexOfT < 0) {
              if (
                docData["post_type"] === "selling" &&
                docData["published"] &&
                !docData["sold"]
              ) {
                docData["id"] = document.id;
                localMaterials.push(docData);
                localStorage.setItem(
                  "published-materials",
                  JSON.stringify(localMaterials)
                );
                reloadMarkers(false);
              }
              if (
                docData["post_type"] === "looking" &&
                docData["published"] &&
                !docData["sold"]
              ) {
                docData["id"] = document.id;
                localTakeMaterials.push(docData);
                localStorage.setItem(
                  "published-take-materials",
                  JSON.stringify(localTakeMaterials)
                );
                reloadMarkers(false);
              }
            }
          }
        }
      }
      if (type == "removed") {
        indexOfM = localMaterials.findIndex(
          (element) => element.id === document.id
        );
        if (indexOfM >= 0) {
          localMaterials.splice(indexOfM, 1);
          localStorage.setItem(
            "published-materials",
            JSON.stringify(localMaterials)
          );
          reloadMarkers(false);
        } else {
          indexOfT = localTakeMaterials.findIndex(
            (element) => element.id === document.id
          );
          if (indexOfT >= 0) {
            localTakeMaterials.splice(indexOfT, 1);
            localStorage.setItem(
              "published-take-materials",
              JSON.stringify(localTakeMaterials)
            );
            reloadMarkers(false);
          }
        }
      }
    };

    const proximityBound = () => {
      zoom.value = 10 - searchProximity.value / 25 + 10;
      mobzoom.value = 10 - searchProximity.value / 25 + 10;
    };


    const toggleModalValue=()=>{
      console.log(modaltrue.value,"jhbfjhf")

if(modaltrue.value)
{
 modaltrue.value=false;

}
else{
   modaltrue.value=true;
}
    
  
    }
    const zoomResized = () => {
      zoom.value = mainMap.value.$mapObject.zoom;
      //mobzoom.value = mainMap.value.$mapObject.zoom;
      if (10 - zoom.value <= 0) {
        searchProximity.value = 25 * (10 - zoom.value) + 250;
      }
      if (searchProximity.value > 250) {
        searchProximity.value = 250;
      }
    };
      const mobzoomResized = () => {
      mobzoom.value = mainMap.value.$mapObject.mobzoom;
     
      if (10 - zoom.value <= 0) {
        searchProximity.value = 25 * (10 - mobzoom.value) + 250;
      }
      if (searchProximity.value > 250) {
        searchProximity.value = 250;
      }
    };

    // Init when loads
    /*navigator.geolocation.getCurrentPosition(position => {
      center.value = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      }
      reloadMarkers(true)
    })*/
    navigator.geolocation.getCurrentPosition(
      (position) => {
        center.value = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        reloadMarkers(true);
      },
      (err) => {
        console.log(err);
        alert(
          `Unable to get current location because: ${err.message}, centering the map to default location`
        );
        center.value = {
          lat: 49.8802322,
          lng: -119.4366328,
        };
        reloadMarkers(true);
      }
    );

    // See if user is loggedIn or not
    if (userData && userRole) {
      loggedIn.value = true;
    }

    // Other materials
    /*
    let otherSubtypes = JSON.parse(localStorage.getItem('other-subtypes'))
    if (otherSubtypes == null) {
      store
        .dispatch('app-material/fetchOthers')
        .then(response => {
          otherSubtypes = response
          localStorage.setItem('other-subtypes', JSON.stringify(response))

          itemTypes = itemTypes.concat(response)
          preloadImages()
        })
        .catch(err => {
          inProgress.value = false
          console.log(err)
        })
    } else {
      itemTypes = itemTypes.concat(otherSubtypes)
    }
    */

    watch(materialSearch, (query) => {
      // eslint-disable-next-line no-unused-expressions
      query && query !== keyword.value && querySearch(query);
    });

    return {
      mainMap,
      canadaWide,
      zoom,
      mobzoom,
      searchAction,
      searchType,
      searchSubtype,
      itemTypes,
      dutMaterial,
      subitemTypes,
      actionTypes,
      searchFilter,
      searchByAction,
      searchPrice,
      showPriceFilter,
      searchWeight,
      showWeightFilter,
      searchProximity,
      showProximityFilter,
      clearSearch,
      materialResults,
      searchMaterials,
      querySearch,
      searching,
      materialSearch,
      modaltrue,
      doSearch,
      selectedMaterial,
      selectedMaterialType,
      keyword,
      rootThemeClasses,
      center,
      materialMarkers,
      materialData,
      filterBox,
      resetFilter,
      toggleFilterDialog,
      metricTypes,
      searchMetricType,
      truckOptions,
      searchTruckingType,
      machineOptions,
      searchMachineType,
      loading,
      relocateMap,
      trunc70,
      getCustom,
      markerPicked,
      markerHover,
      markerSelected,
      markerClear,
      humanReadable,
 getTheCity,
      loadImages,
      changeText,
      resetText,
      callPhoneNumber,
      openPitURL,
      whichImage,
      closeDialog,
      imageDialogVisible,
      openLink,
      originalText,
      materialPicked,
      picIndex,
      prevImage,
      nextImage,
      searchMaterial,
      highlightSale,
      highlightTake,
      postAd,
      loggedIn,
      goLogin,
      goRegister,
      goLogout,
      goMyAccount,
      proximityBound,

      zoomResized,
      mobzoomResized,
      toggleModalValue,
      mobileView,
      icons: {
        mdiMapMarker,
        mdiImageMultiple,
        mdiChevronLeft,
        mdiChevronRight,
        mdiFilterVariant,
        mdiWeb,
        mdiLogin,
        mdiAccountCircle,
        mdiLogout,
        mdiPlus,
      },
    };
  },
};
</script>
<style lang="scss" scoped>

.v-btn.v-size--x-large {
  font-size: 1.3rem;
}
.v-btn:not(.v-btn--round).v-size--x-large {
  height: 61px !important;
}
.v-btn > .v-btn__content .black-icon {
  color: black;
}
.custom-title {
  font-size: 20px; /* Adjust the font size to your preference */
}
.container {
  display: flex;
  justify-content: flex-end;
}
.container1 {
  display: flex;
  justify-content: flex-start;
 
}
.flex-end {
  align-self: flex-end;
  position: absolute;
  bottom: 50px;
  right: 10px;
}
.flex-starts {
  align-self: flex-start;
  position: absolute;
  bottom:0px;
  margin-bottom: 50px;
  font-weight: bold;
  left: 10px;
}
.canada-wide {
  position: absolute;
  z-index: 1000;
  right: 60px;
  padding: 10px 0 0 0;
  color: black;
  font-weight: bold;
  font-size: 2rem;
}
.canada-wide.mobile {
  right: 50px;
  padding: 15px 0 0 0;
  font-size: 1.2rem;
}
.preview {
  max-width: 400px;
  max-height: 400px;
}
.pit-preview {
  text-align: center;
}
.btn-hero {
  width: 90%;
}
.action-buttons {
  float: right;
}
.hero-logo {
  position: absolute;
  width: 100%;
  text-align: center;
  z-index: 10;
  top: 55px;
  left: 0px;
}
.hero-logo-sm {
  position: absolute;
  width: 100%;
  text-align: center;
  z-index: 10;
  top: 115px;
  left: 0px;
}
.hero-logo > img {
  height: 175px;
}
.hero-logo-sm > img {
  height: 135px;
}
.scroll-column {
  overflow-y: auto;
  height: 45rem;
}
.center-aligned {
  align-items: center;
}
.home-link:hover {
  text-decoration: underline;
}
.home-link {
  color: white;
  text-decoration: none;
}
.hero-row {
}
.hero-sub-image {
  /*background: linear-gradient(90deg, rgba(0, 178, 0, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(221, 4, 38, 1) 100%);*/
  /*background: linear-gradient(90deg, #00b200, white, #dd0426),
    url('../assets/images/misc/fractured-clear-rock-with-2-or-more-flat-sides.png');*/
  background: linear-gradient(90deg, #dd0425d8, #00b200d9),
    url("../assets/images/misc/materials/fractured-clear-rock-with-2-or-more-flat-sides.png")
      no-repeat top center;

  /* Set a specific height */
  min-height: 100px;
  /* Position and center the image to scale nicely on all screens */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.take-color {
  /* Set a specific height */
  min-height: 50px;
  /*background-color: #00b200;*/
  background: rgb(0, 178, 0);
  background: linear-gradient(
    90deg,
    rgba(0, 178, 0, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}
.sell-color {
  /* Set a specific height */
  min-height: 150px;
  /*background-color: #dd0426;*/
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(221, 4, 38, 1) 100%
  );
}
.sell-transluscent {
  border: 1px solid black !important;
  background-color: #fdeff1;
}
.sell-darker {
  background-color: #fc9eab;
}
.take-img {
  /* Set a specific height */
  min-height: 200px;
  /* Use "linear-gradient" to add a darken background effect to the image (photographer.jpg). This will make the text easier to read */
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0)),
    url("../assets/images/pages/homebg-take.png");
}
.take-transluscent {
  border: 1px solid black !important;
  background-color: #f2fff2;
}
.take-darker {
  background-color: #aefcae;
}
.sell-img {
  /* Set a specific height */
  min-height: 200px;
  /* Use "linear-gradient" to add a darken background effect to the image (photographer.jpg). This will make the text easier to read */
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0)),
    url("../assets/images/pages/homebg-sell.png");
}
.pit-transluscent {
  border: 1px solid rgb(115, 115, 115) !important;
  background-color: rgba(115, 115, 115, 0.25);
}
.pit-darker {
  background-color: rgba(115, 115, 115, 0.65);
}
.hero-image {
  /* Position and center the image to scale nicely on all screens */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

/* Place text in the middle of the image */
.hero-text {
  text-align: center;
  color: white;
  width: 100%;
  height: 100%;
  font-size: 30px;
  padding: 0px 115px;
}
.image-gallery {
  width: "100%";
  height: 100px;
  background-color: white;
  border-radius: 15px;
}
.icon-logo {
  position: absolute;
  top: 10px;
  left: 10px;
  width: auto;
  height: 35px;
}

.picimg {
  width: "100%";
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
}
.displaycontainer{
  @media (max-width: 768px) {
    display:none;
  }
}
.mobileDisplay{
   @media (min-width: 768px) {
    display:none;
  }
}


</style>
